<template>
  <section>
    <b-row>
      <b-col md="6">
        <validation-provider
          v-slot="validationContext"
          ref="first_name"
          :rules="{ required: true }"
          vid="first_name"
          name="First Name"
        >
          <b-form-group
            label="First Name"
            label-for="first-name-input"
          >
            <b-form-input
              id="first-name-input"
              v-model="user.first_name"
              placeholder="First Name"
              maxlength="255"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback>
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="validationContext"
          ref="last_name"
          :rules="{ required: true }"
          vid="last_name"
          name="Last Name"
        >
          <b-form-group
            label="Last Name"
            label-for="last-name-input"
          >
            <b-form-input
              id="user-lastname-input"
              v-model="user.last_name"
              placeholder="Last Name"
              maxlength="255"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <validation-provider
          v-slot="validationContext"
          ref="profile_image"
          :rules="{ required: true }"
          vid="profile_image"
          name="Profile Image"
        >
          <b-form-group
            label="Photo"
            label-for="photo-input"
          >
            <div class="photo-wrapper">
              <fileUpload
                v-model="show"
                lang-type="en"
                field="profile_img"
                :width="80"
                :height="80"
                img-format="png"
                :lang-ext="Object.assign({}, cropperSettings)"
                @srcFileSet="cropSuccess"
                @crop-success="cropSuccess"
              />
              <b-media>
                <template #aside>
                  <b-img
                    v-if="!imgDataUrl"
                    blank
                    blank-color="#ccc"
                    width="80"
                    alt="placeholder"
                  />
                  <img
                    v-else
                    :src="imgDataUrl"
                    alt="profile_image"
                  >  
                </template>
                <div class="media-body mt-75 ml-75">
                  <b-button
                    variant="primary"
                    @click="showCropperDialog"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Upload</span>
                  </b-button>
                  <p class="card-text">
                    <small>Allowed JPG, GIF, BMP or PNG. Max size of 5MB</small>
                  </p>
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </div>
              </b-media>
            </div>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <validation-provider
          v-slot="validationContext"
          ref="phoneNumber"
          vid="phone"
          name="Phone Number"
          :rules="phoneRules"
        >
          <b-form-group
            label="Phone"
            label-for="phone-number-input"
          >
            <aom-tel-input
              id="phone-number-input"
              v-model="user.phone"
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <validation-provider
          v-slot="validationContext"
          ref="email"
          rules="required|email"
          name="email"
        >
          <b-form-group
            label="Email"
            label-for="email-input"
          >
            <b-form-input
              id="email-input"
              v-model="user.email"
              placeholder="Email"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <user-roles-select
          v-model="selectedUserRoles"
          :list-type="isProgramTypeTraining ? 'trainee': 'participant'"
          :multiple-roles-enabled="multipleRolesEnabled"
          @update="updateUserRole"
        />
      </b-col>
      <b-col md="6">
        <b-form-checkbox
          v-model="userEmailPreferences"
          name="check-button"
          switch
          inline
        >
          Email Notifications
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="hasRoleMentor"
        md="6"
        class="mt-1"
      >
        <validation-provider
          v-slot="validationContext"
          ref="allowedMentees"
          vid="match_limit"
          name="Allowed Mentees"
          rules="required"
        >
          <b-form-group
            label="Number of Mentees allowed"
            label-for="number-of-mentees-allowed"
          >
            <b-form-select
              id="number-of-mentees-allowed"
              v-model="numberOfMenteesAllowed"
              placeholder="Select the Number of Mentees allowed"
              :options="numberOfMenteesAllowedSet"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col
        md="6"
        class="mt-1"
      >
        <validation-provider
          ref="languages"
          name="languages"
        >
          <b-form-group
            label="Language"
            label-for="selected-language"
          >
            <v-select
              id="selected-language"
              v-model="selectedLanguage"
              :selectable="(option) => option.id"
              :dir="dir"
              :options="languages"
              label="name"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="isCreateUser"
        md="6"
      >
        <b-form-checkbox
          v-model="participantStatus"
          name="participant-active"
          switch
          inline
        >
          Active / InActive
        </b-form-checkbox> 
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormInvalidFeedback,
  BMedia,
  BImg,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import UserRolesSelect from '@/@aom-core/UserRolesSelect.vue';
import fileUpload from 'vue-image-crop-upload/upload-2.vue';
import { userRolesDisplay, userRoles, locales, UserProgramSettings, userStatus, programTypes } from '@models';
import {
  getValidationState
} from "@/libs/utils";
import { mapGetters } from 'vuex';
import programsService from "@/services/programsService";
import { makeErrorToast } from "@/libs/utils";

export default {
  name: 'ParticipantForm',  
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    ValidationProvider,
    BMedia,
    BImg,
    UserRolesSelect,
    BFormCheckbox,
    fileUpload,
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    needToClearForm: {
      type: Boolean
    }
  },
  data() {
    return {
      multipleRolesEnabled: true,
      numberOfMenteesAllowed: undefined,     
      selectedLanguage: undefined,
      userEmailPreferences: undefined,
      show: false,
      params: {
        token: '123456798',
        name: 'avatar'
      },
      headers: {
        smail: '*_~'
      },
      imgDataUrl: '',
      cropperSettings: {
        preview: 'Preview',
        btn: {
          off: 'Cancel',
          close: 'Close',
          back: 'Back',
          save: 'Save'
        },
        error: {
          onlyImg: 'Please make sure your file is JPG, GIF, BMP or PNG and less than 5mb.',
          outOfSize: 'Please make sure your file is JPG, GIF, BMP or PNG and less than ',
        }
      },
      isUpdating: false,
      selectedUserRoles: [],
      languages: [],
      numberOfMenteesAllowedSet: [],
      participantStatus: true
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram']),
    dir(){
      return 'ltr';
    },
    hasRoleMentor () {
      return this.user.user_roles.some(roles => roles.role_id === userRoles.MENTOR);
    },
    hasRoleMentee () {
      return this.user.user_roles.some(roles => roles.role_id === userRoles.MENTEE);
    },
    userProgramEmailPref() {
      const email = this.user.user_program_settings.filter(s => Number(s.program_id) === Number(this.$route.params.id)).shift();
            return email?.email_notifications;
    },
    isCreateUser() {
      return this.user.id === undefined || this.user.id === null;
    },
    isProgramSSOEnabled() {
      return this.defaultProgram?.program_saml?.is_enabled ?? false;
    },
    phoneRules() {
      if (this.isProgramSSOEnabled) {
        return 'aom_phone';
      }
      return this.defaultProgram?.compulsory_phone_number
        ? 'required|aom_phone'
        : 'aom_phone';
    },
    isProgramTypeTraining() {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    }
  },
  watch: {
    'user.user_roles': {
      handler(n) {
        if(n && n.length > 0) {
          n.forEach(r => {
            if(r.match_limit && r.role_id === userRoles.MENTOR) {
              if(Number(r.program_id) === Number(this.$route.params.id)) {
                this.numberOfMenteesAllowed = r.match_limit;
              }
            } 
            if( this.selectedUserRoles.some(roles => roles.id === r.role_id)) {
              return;
            }
            if(r.role_id === userRoles.MENTEE || r.role_id === userRoles.MENTOR || r.role_id === userRoles.TRAINEE) {
              return  this.selectedUserRoles.push({id: r.role_id, name: userRolesDisplay[r.role_id] });
            }
            
          }
          );
        }
      },
      deep: true,
      immediate: true
    },
    'user.id': {
      handler(n) {
        if (this.isCreateUser) {
          this.multipleRolesEnabled = this.defaultProgram?.multiple_roles_enabled;
        } else {
          this.multipleRolesEnabled = this.defaultProgram?.multiple_roles_enabled || this.user.user_roles.filter(roles => roles.role_id === userRoles.MENTOR || roles.role_id === userRoles.MENTEE  || roles.role_id === userRoles.TRAINEE).length > 1;
        }
      },
      deep: true,
      immediate: true
    },
    'user.profile_image.url': {
      handler(n) {
        if(n) {
          this.imgDataUrl = n;
        }
      },
      immediate: true
    },
    selectedLanguage: {
      handler(n) {
        if(n && Array.isArray(n) && n.length > 0) {
          return this.user.preferred_language_id = n[0].id;
        } else if (n && n.id) {
          return this.user.preferred_language_id = n.id;
        }
      },
      immediate: true
    },
    numberOfMenteesAllowed: {
      handler(n) {
        if(n && this.user.user_roles.length > 0) {
          const userRoleMentor =  this.user.user_roles.filter(r => r.role_id === userRoles.MENTOR).shift();
          const otherRolesArray = this.user.user_roles.filter(r => r.role_id !== userRoles.MENTOR);
          const updateMatchLimit = [{
            ...userRoleMentor,
            match_limit: n
          }];
          this.user.user_roles =
            [
              ...otherRolesArray,
              ...updateMatchLimit
            ];
        }
      },
      immediate: true
    },
    userEmailPreferences: {
      handler(n) {
        if(this.userProgramEmailPref !== undefined && n !== undefined) {
          const userEmailForProgram = this.user.user_program_settings.filter(s => Number(s.program_id) === Number(this.$route.params.id)).shift();
          this.user.user_program_settings = [{
            ...userEmailForProgram,
            email_notifications: n
          }];
        } 
        if(this.userProgramEmailPref === undefined) {
          this.user.user_program_settings = [{
            ...new UserProgramSettings({program_id: this.$route.params.id, email_notifications: false}),
          }];
        }

      },
      immediate: true
    }, 
    'user.user_program_settings': {
      handler() {
        if(this.userProgramEmailPref !== undefined) {
          this.userEmailPreferences = this.userProgramEmailPref;
        }
      },
      immediate: true,
      deep: true
    },
    participantStatus: {
      handler(n) {
        this.user.status_id = n ? userStatus.ACTIVE : userStatus.INACTIVE;
      },
      immediate: true,
      deep: true
    },
    needToClearForm(n) {
      if (n) {
        this.clearForm();
      }
    },
    defaultProgram() {
      if (this.isProgramTypeTraining && this.isCreateUser) {
        let role = [
          {
            id: userRoles.TRAINEE,
            name: userRolesDisplay[userRoles.TRAINEE]
          }
        ];
  
        this.updateUserRole(role);
      }
    }
  },
  async created() {
    try {
      const id = this.$route.params.id;
      const response = await programsService.getProgramByProgramId(id);
      if (response && response.data) {
        this.setLanguages(response.data.languages);
        this.setMaxMenteePerMentor(response.data.program_roles);
      }
    } catch (e) {
      this.$toast(makeErrorToast("Program not loaded"));
      this.$log.error(e);
    }
  },
  methods: {
    clearForm() {
      this.imgDataUrl = undefined;
      this.selectedUserRoles = [];
      if (this.isProgramTypeTraining && this.isCreateUser) {
        let role = [
          {
            id: userRoles.TRAINEE,
            name: userRolesDisplay[userRoles.TRAINEE]
          }
        ];
  
        this.updateUserRole(role);
      }
      this.$emit('cleared');
    },
    showCropperDialog() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
      this.user.profile_image.data_uri = imgDataUrl;
    },
    updateUserRole(role) {
      this.selectedUserRoles = role;
      this.user.user_roles = role.map(r => ({role_id: r.id, program_id: Number(this.$route.params.id)}));
    },
    setMaxMenteePerMentor (programRoles) {
      const maxMentees = programRoles.filter(r => r.role_id === userRoles.MENTOR);
      if(maxMentees[0]?.match_limit) {
        this.numberOfMenteesAllowedSet = Array.from({length: maxMentees[0].match_limit}, (_, i) => i + 1);
      }
    },
    setLanguages(langs) {
      this.languages = langs
        .map(lang => ({
          id: lang.id,
          name: lang.name,
          abbreviation: lang.abbreviation,
        }));
      if(this.user.preferred_language_id === null || this.user.preferred_language_id === undefined) {
        this.selectedLanguage = this.languages.filter(lang => lang.id === locales.EN);
      } else {
        this.selectedLanguage = this.languages.filter(lang => lang.id === this.user.preferred_language_id);
      }
      this.locales = this.languages.map(l => l.id);
    }
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script>
<style lang="scss" scoped>
.photo-wrapper {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
}
</style>
