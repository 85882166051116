<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      id="unpublishMatch"
      ref="unpublishMatch"
      v-model="showModal"
      no-close-on-esc
      no-close-on-backdrop
      :title="'Notify ' + roleAlias(userRoles.MENTOR, defaultProgram)"
      centered
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <p>
        {{ message }}
      </p>
      <template #modal-footer="{ ok, cancel}">
        <b-button
          size="md"
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          size="md"
          variant="primary"
          @click="ok"
        >
          <b-spinner
            v-if="isSubmitting"
            small
          />
          <span
            v-if="isSubmitting"
          > Updating...</span>
          <span v-else>Confirm</span>
        </b-button>
      </template>
    <!-- eslint-disable vue/no-v-html -->

    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { matchStatus, userRoles } from "@/models";
import AomDatePicker from "@aom-core/AomDatePicker";
import { localDateAndTimeToUtc } from "@/libs/utils";
import { matchesService } from "@services";
import { makeErrorToast, makeSuccessToast, getValidationState } from "@/libs/utils";
//eslint-disable-next-line
import { required } from "@validations";
import vSelect from "vue-select";
import {
  BSpinner,
  BFormTextarea,
  BForm,
  BModal,
  BButton,
  BFormInvalidFeedback,
  BFormTimepicker,
  BFormRadioGroup,
  BFormGroup,
  BRow,
  BCol,
} from "bootstrap-vue";
import { matchCancellationReasons } from "./matchCancellationReasons";
import { mapGetters } from 'vuex';
import { MAX_INFORM_MENTOR_IN_LOCKED_MATCH, roleAlias } from "@/@aom-core/utils/utils";

export default {
  components: {
    vSelect,
    BSpinner,
    BFormTextarea,
    BModal,
    BButton,
    BFormInvalidFeedback,
    BForm,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    BFormTimepicker,
    AomDatePicker,
    BFormRadioGroup,
    BRow,
    BCol,
  },
  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
    matches: {
      type: Array,
      default: () => [],
    },
    programId: {
      type: [Number, String],
      default: 0,
    }
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    isOtherSelected() {
      return this.note !== null && this.note.isOther;
    },
    message () {
      return 'Do you want to send a notification to the ' + roleAlias(userRoles.MENTOR, this.defaultProgram) + ' that they are locked in a match with the ' + roleAlias(userRoles.MENTEE, this.defaultProgram) + '?';
    }
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      selectedMatchStatus: matchStatus.ACTIVE,
      matchStartDate: new Date(),
      matchStartTime: "09:00",
      minStartDate: new Date(),
      note: null,
      isSubmitting: false,
      reasons: matchCancellationReasons,
      otherReason: ""
    };
  },
  methods: {
    open() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    async onSubmit() {
      this.isSubmitting = true;
      const programId = this.$route.params.id;
      let result = null;
      let notifiables = this.matches.filter(match => match.notify_mentor_count < MAX_INFORM_MENTOR_IN_LOCKED_MATCH);
     
      if (notifiables.length >= 1) {
        result = await matchesService.notifyMentor(programId, {
          matches: notifiables
        });
      }
      if (result) {
        this.$toast(makeSuccessToast('Mentor notified successfully.'));
      }
      this.$emit("mentorNotified");
      this.showModal = false;
      this.isSubmitting = false;
    },
    clearForm() {
      this.showModal = false;
      this.isSubmitting = false;
    },
  },
  setup() {
    return {
      getValidationState,
      userRoles,
      roleAlias
    };
  }
};
</script>

<style>
</style>