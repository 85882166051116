<template>
  <b-modal
    id="modal-add-training"
    v-model="showModal"
    title="Assign a license"
    centered
    ok-title="Assign training"
    cancel-variant="outline-secondary"
    size="lg"
    :ok-disabled="isSubmitting"
    @hidden="onHidden"
    @ok.prevent="onSubmit"
  >
    <b-card>
      <p>Participant trainings</p>
      <b-form>
        <b-list-group
          v-for="(training, index) in trainings"
          :key="index"
        >
          <b-list-group-item class="d-flex align-items-center">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="mb-0"
            >
              <div class="row">
                
                <div
                  class="col-6"
                >
                  <h5 class="mt-50 mb-0">
                    {{ training.course_name }}
                  </h5>
                </div>
                <div class="col-4">
                  <feather-icon
                    v-b-tooltip.hover.top="training.description"
                    icon="AlertCircleIcon" 
                    class="mr-50 my-icon"
                    size="20"
                  />
                  <span class="mr-20">{{ training.seats_available - training.program_training_seats_count }} licenses available</span>
                </div>
                <div class="col-2">
                  <b-form-checkbox-group
                    v-model="selectedTrainings"
                    :aria-describedby="ariaDescribedby"
                  >
                    <b-form-checkbox
                      :value="{id: training.id}"
                      :disabled="training.seats_available - training.program_training_seats_count <= 0"
                      />
                  </b-form-checkbox-group>
                </div>
              </div>
            </b-form-group>
          </b-list-group-item>
        </b-list-group>
      </b-form>
    </b-card>
  </b-modal>
</template>

<script>
import { 
  BCard, BForm, BModal, BListGroup, BListGroupItem,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  VBTooltip
 } from "bootstrap-vue";
import vSelect from "vue-select";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import _debounce from "lodash/debounce";
import trainingsService from "@/services/trainingsService";
import { programTypes, userRoles, userRolesDisplay } from "@/models";

export default {
  components: {
    BCard,
    BForm,
    BModal,
    vSelect,
    BListGroup, 
    BListGroupItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    VBTooltip
  },
  props: {
    showModal: {
      type: Boolean
    },
    program: {
      type: Object,
      default: () => {}
    },
    assignedTrainings: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      keyword: null,
      selectedCourses: [],
      trainings: [],
      dir: "ltr",
      isLoading: false,
      isSubmitting: false,
      selectedTrainings: []
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    onHidden() {
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    clearForm() {
      this.selectedCourses = [];
    },
    async onSubmit() {
      console.log('this.$route.params.id', this.$route.params)
      this.isSubmitting = true;
      try {
        await trainingsService.assignTrainings(this.$route.params.id, this.$route.params.participantId, {
          trainings: this.selectedTrainings,
        });
        this.$toast(makeSuccessToast("Training was assigned successfully"));
        this.$emit("trainingAssigned");
        this.$emit("hiddenModal", false);
        this.clearForm();
      } catch (e) {
        this.$toast(makeErrorToast(e));
        this.$log.error(e);
      } finally {
        this.isSubmitting = false;
      }
    },
    debounceInput: _debounce(function (value) {
      this.keyword = value;
      this.loadItems();
    }, 500),
    async loadItems() {
      this.selectedTrainings = [...this.assignedTrainings];
      this.isLoading = true;
      let columnFilters = [];
      if (this.keyword) {
        columnFilters.push({
          field: "course_name",
          value: this.keyword,
        });
      }
      try {
        const response = await trainingsService.getProgramTrainings(this.program.id, {
          columnFilters: columnFilters,
        });
        this.trainings = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Training list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
